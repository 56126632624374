@charset "UTF-8";
@import "~@/assets/sass/_function";

.Loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  //background-color: rgba($bg-color, 0.3);
  .rays-loading {
    width: 60px;
    height: 48px;
    background: url(https://oss.5rs.me/oss/uploadfe/png/454a148745f00b2268c607d896f5446d.png) 0/100% 100%;
    position: relative;
    &-rect {
      position: absolute;
      clip: rect(20px, 60px, 48px, 0px);
      background: url(https://oss.5rs.me/oss/uploadfe/png/164535431651db226b69bbe4b7e58ffd.png) 0/100% 100%;
      width: 100%;
      height: 100%;
      animation: rays-loading 3s linear infinite;
      @keyframes rays-loading {
        0% {
          clip: rect(60px, 60px, 48px, 0);
        }
        80% {
          clip: rect(0px, 60px, 48px, 0);
        }
        100% {
          clip: rect(0px, 60px, 48px, 0);
        }
      }
    }
  }

  // $size: 48;
  // 改用font-size控制大小
  font-size: 48px;

  .rig-loading {
    $size: 1em;
    width: $size;
    height: $size;
    background-image: url(https://oss.5rs.me/oss/uploadfe/png/7d3897caa198ee5cddcb926b4c1ef714.png);
    background-repeat: no-repeat;
    background-size: $size * 8 $size * 5;
    background-position: 0 0;
    animation: rigLoading 2s linear infinite;
    transform: scale(1.5);

    @keyframes rigLoading {
      @for $i from 0 to 5 {
        @for $j from 0 to 8 {
          #{calc(($i * 8 + $j) / 40 * 100) + "%"} {
            background-position: $j * $size * -1 $i * $size * -1;
          }
          #{calc(($i * 8 + $j) / 40 * 100 + 2.4999) + "%"} {
            background-position: $j * $size * -1 $i * $size * -1;
          }
        }
      }
    }
  }
}
