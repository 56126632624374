/** 修改antd组件默认样式为系统设计规范 */
@import "variables";

.RangePicker {
  width: 100%;
}

// -------- ant-btn -------
.ant-btn {
  gap: 4px;
  .ant-btn-icon {
    line-height: 1;
  }
  // primary disabled
  &.ant-btn-primary[disabled]:not(.ant-btn-dangerous):not(.ant-btn-background-ghost) {
    border-color: #b1b4ed;
    background: #b1b4ed;
    color: #fff;
  }
  // primary ghost disabled
  &.ant-btn-primary.ant-btn-background-ghost[disabled] {
    border-color: #b1b4ed;
    background: #fff;
    color: #b1b4ed;
  }
}

// ------- ant-pagination -------
.ant-pagination {
  position: relative;
  .ant-pagination-total-text {
    position: absolute;
    left: 0;
    font-size: 14px;
    color: $font_gy_2;
  }
  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next {
    border: 1px solid #dcdcdc;
    background: #fff;
    &:hover:not(.ant-pagination-disabled) {
      border-color: $primary-color;
      background: #fff;
      .ant-pagination-item-link {
        background: transparent;
      }
    }
    .ant-pagination-item-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .ant-pagination-item-active {
    border-color: $primary-color;
    background: #fff;
    font-weight: normal;
    a {
      color: $colorPrimary;
    }
  }
}

// ------- scroll-bar -------
* {
  // fix: 解决table底部滚动条样式不生效bug
  scrollbar-width: auto;
  scrollbar-color: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background-color: #ccc;
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 10px;
    background: transparent;
  }
}
// ------- hover scrollbar -------
.hover_scrollbar_oIFwwwm {
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
  }
}

// ------- ant-table -------
.ant-table {
  border: 1px solid #eeeeee;
  .ant-table-thead {
    .ant-table-cell {
      // background: $primary-color-4;
      color: $text-color;
      font-weight: var(--fw500);
      font-size: 14px;
      &::before {
        display: none;
      }
    }
  }
  tr {
    td {
      &.ant-table-selection-column {
        padding-right: 16px;
      }
    }
  }
}

// ------- ant-select -------
.ant-select {
  &-arrow {
    svg {
      opacity: 0;
    }
    &:has(svg):after {
      font-family: "ic";
      font-size: 16px;
      color: $text-color-placeholder;
      content: "\eb8a";
    }
  }
  &-focused {
    .ant-select-arrow {
      &:has(svg):after {
        color: $primary-color;
      }
    }
  }
}
.ant-select-selector {
  display: flex;
}

// ------- 下拉框 ant-select-dropdown -------
.ant-select-dropdown {
  .rc-virtual-list .ant-select-item {
    &:not(:last-child) {
      // margin-bottom: 4px; // 导致最后一个没显示出来
    }
  }
}

// ------- ant-tabs -------
.ant-tabs {
  .ant-tabs-nav-wrap {
    .ant-tabs-tab {
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          font-weight: var(--fw500);
          text-shadow: none;
        }
      }
    }
    .ant-tabs-nav-list {
      .ant-tabs-ink-bar {
        height: 3px;
      }
    }
  }
}

// ------- ant-checkbox -------
.ant-checkbox {
  display: inline-block;
}

// ------- ant-input -------
.ant-input {
  // 处理自动填充时的样式
  &:-webkit-autofill {
    // box-shadow: 0 0 0 30px white inset !important;
    // 字体色
    -webkit-text-fill-color: $font_gy_1;
    // 背景色
    background-color: transparent;
    transition: background-color 5000s ease-in-out 0s;
  }
}

// ------- ant-drawer -------
.ant-drawer {
  .ant-drawer-header-title {
    display: flex;
    flex-direction: row-reverse;
    .ant-drawer-close {
      margin: 0;
    }
  }
}
