@import "~@/assets/sass/_common.scss";
@import "~@/assets/sass/_animation.scss";
@import "~@/assets/sass/_base";

@import "~@/assets/sass/_antd.scss";

body {
  // 定义全局css 变量
  --minWidth: 1280px; // 兼容1280
  --borderBase: 4px;
  --modalZIndex: 1000;
  --headerIndex: 100; // header z-index
  --headerHeight: 60px;

  // MacOS font-weight:500; WinOS font-weight:600;
  // 以后请用：
  //    font-weight: $fw500;
  //    font-weight:var(--fw500);
  --fw500: 600;
  &[ismac] {
    --fw500: 500;
  }
}

.fw500 {
  font-weight: var(--fw500);
}

.ic {
  // icon上下没有居中
  line-height: 1;
  font-weight: normal;
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  min-width: var(--minWidth);
  p {
    margin-bottom: 0;
  }
  /** 默认输入框 */
  .input {
    line-height: 32px;
    padding: 0 8px;
    border-radius: 3px;
    //border: $border-color 1px solid;
    //&::placeholder {
    //color: $text-color-placeholder;
    //}
  }

  // @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 120dpi) {
  //   width: 150vw;
  //   height: 150vh;
  //   transform: scale(0.6667, 0.6667);
  //   transform-origin: 0 0;
  // }
}

@font-face {
  font-family: "DIN Alternate";
  src: url(https://cdn.5rs.me/cdn/fonts/DINAlternateBold.ttf);
}
@font-face {
  font-family: "SourceHanSansCN-Regular";
  src: url(https://cdn.5rs.me/font/aipainter/SourceHanSansCN-Regular.otf);
}
@font-face {
  font-family: "Alimamablack";
  src: url(https://cdn.5rs.me/cdn/font/ttf/Alimamablack.ttf);
}

@font-face {
  font-family: "DIN Condensed";
  src: url(https://cdn.5rs.me/cdn/font/ttf/DINCondensed.ttf);
}
