@charset "UTF-8";
@import "variables";
@import "mixin";

/** font size **/
.f12 {
  font-size: 12px;
  line-height: 20px;
}

.f14 {
  font-size: 14px;
  line-height: 22px;
}

.f16 {
  font-size: 16px;
  line-height: 24px;
}

.f18 {
  font-size: 18px;
  line-height: 26px;
}

.f20 {
  font-size: 20px;
  line-height: 28px;
}

.f24 {
  font-size: 24px;
  line-height: 32px;
}

.f30 {
  font-size: 30px;
  line-height: 38px;
}

.f46 {
  font-size: 46px;
  line-height: 54px;
}

.f68 {
  font-size: 68px;
  line-height: 76px;
}

/** width **/
.w120 {
  width: 120px;
}

.w160 {
  width: 160px;
}

// margin
.mt0 {
  margin-top: 0px !important;
}
.mt2 {
  margin-top: 2px !important;
}
.mt5 {
  margin-top: 5px !important;
}
.mt8 {
  margin-top: 8px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}
.mt16 {
  margin-top: 16px !important;
}

.mt20 {
  margin-top: 20px !important;
}
.mt24 {
  margin-top: 24px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt60 {
  margin-top: 60px !important;
}
.mt100 {
  margin-top: 100px !important;
}
.mt300 {
  margin-top: 300px !important;
}

.mr4 {
  margin-right: 4px !important;
}

.mr5 {
  margin-right: 5px !important;
}
.mr6 {
  margin-right: 6px !important;
}
.mr8 {
  margin-right: 8px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr12 {
  margin-right: 12px !important;
}

.mr15 {
  margin-right: 15px !important;
}
.mr16 {
  margin-right: 16px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr24 {
  margin-right: 24px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb12 {
  margin-bottom: 12px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}
.mb32 {
  margin-bottom: 32px !important;
}
.ml3 {
  margin-left: 3px !important;
}
.ml5 {
  margin-left: 5px !important;
}
.ml8 {
  margin-left: 8px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.ml12 {
  margin-left: 12px !important;
}
.ml15 {
  margin-left: 15px !important;
}

.ml16 {
  margin-left: 16px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml24 {
  margin-left: 24px !important;
}

.ml30 {
  margin-left: 30px !important;
}
.ml32 {
  margin-left: 32px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.ml120 {
  margin-left: 120px !important;
}
.min-width0 {
  min-width: 0;
}

.gap10 {
  gap: 10px;
}

.gap12 {
  gap: 12px;
}

.gap15 {
  gap: 15px;
}

.gap20 {
  gap: 20px;
}

.gap30 {
  gap: 30px;
}

// display
.inline-block {
  display: inline-block !important;
  vertical-align: middle !important;
}

.text-underline {
  text-decoration: underline;
}
.text-bold {
  font-weight: $fw500;
}
.text-bold-600 {
  font-weight: $fw600;
}
.text-link {
  color: $text-color-link;
  cursor: pointer;
}

// width、height
.h100 {
  height: 100%;
}

.w100 {
  width: 100px;
}

.block {
  display: block;
}

//flex
.dis-flex {
  display: flex;
}

//flex
.flex-inline {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.row-flex {
  flex-direction: row;
}

.col-flex {
  flex-direction: column;
}

.flex-justify {
  justify-content: space-between;
}

.flex-around {
  justify-content: space-around;
}

.align-base {
  align-self: baseline;
}

.flex1 {
  flex: 1;
  flex-shrink: 0;
}

.nowrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor {
  cursor: pointer;
  &:hover {
    color: $primary-color-hover !important;
  }
}
.default-cursor {
  cursor: pointer;
  &:hover {
    color: $primary-color !important;
  }
}

.required {
  position: relative;
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    color: $error-color;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
  }
}

.hidden {
  display: none !important;
}
.fixed-hide {
  position: fixed;
  left: -10000px;
  top: -10000px;
}

.table-ope-btn {
  color: $primary-color;
  cursor: pointer;
}

.text-primary {
  color: $primary-color;
}
.text-primary-btn {
  color: $primary-color;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    color: $primary-color-hover;
  }
  &:active {
    color: $primary-color-active;
  }
}
.font_gy_1 {
  color: $font_gy_1;
}
.font_gy_2 {
  color: $font_gy_2;
}
.font_gy_3 {
  color: $font_gy_3;
}
.font_gy_4 {
  color: $font_gy_4;
}
.text-gray3 {
  color: $font_gy_4;
}

.text-gray4 {
  color: $font_gy_3;
}

.text-gray6 {
  color: $font_gy_2;
}

.text-gray9 {
  color: $font_gy_1;
}

.page-title {
  display: inline-flex;
  position: relative;
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 600;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: rgba($primary-color, 0.2);
  }
}

/** 标准容器 */
.container {
  background-color: $bg-color-container;
  //border-radius: radius;
}

.head-large {
  @include fontDValue(36px, var(--fw500));
}

.head {
  @include fontDValue(28px, var(--fw500));
}

.head-small {
  @include fontDValue(24px, var(--fw500));
}

/** 标题 */
.title-large {
  @include fontDValue(20px, var(--fw500));
}

.title {
  @include fontDValue(16px, var(--fw500));
}

.title-small {
  @include fontDValue(14px, var(--fw500));
}

/** 正文 */
.text-large {
  @include fontDValue(16px);
}

.text {
  @include fontDValue(14px);
}

.text-small {
  @include fontDValue(12px);
}

.text-error {
  color: $error-color;
}
.text-warning {
  color: $warning-color;
}
.text-success {
  color: $success-color;
}

// 表头，筛选器 颜色
.th {
  background-color: $bg-color-container;
  border: 1px solid $border-color;
}

// antd Form组件样式替换
.ant-form-item {
  .ant-form-item-explain-error {
    color: $error-color;
    font-size: 12px;
  }
}

.new-scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
    height: 5px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #eeeeee;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }
}

.SomeCurrent-Select {
  .ant-select-dropdown {
    .rc-virtual-list-scrollbar {
      background: none;
      box-shadow: none;
      .rc-virtual-list-scrollbar-thumb {
        background-color: #ccc !important;
        box-shadow: none;
      }
    }
    .rc-virtual-list-holder-inner {
      padding: 0 6px;
      .ant-select-item {
        border-radius: 3px;
      }
    }
    .ant-select-item-option-active {
      color: rgba(0, 0, 0, 0.9);
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background: #f3f3f3;
    }
    .ant-select-item-option-active {
      background: rgba(0, 106, 255, 0.06) !important;
      color: #006aff;
    }
  }
}

// 旋转动画
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

// 通用的高亮按钮，多用于返回按钮
.highlight-btn {
  background-color: rgba($primary-color, 0.1) !important;
  color: $primary-color !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

// 部分通用表格样式
.SomeCurrent-Table {
  .ant-table-thead {
    .ant-table-cell {
      font-weight: $fw500;
      background: #f5f7fa;
      &::before {
        display: none;
      }
    }
  }
  .ant-table-tbody {
    > tr {
      &:last-of-type {
        .ant-table-cell {
          border-bottom: none;
        }
      }
    }
  }
  .ant-table-content {
    border: 1px solid #f5f7fa !important;
    border-radius: 4px !important;
    &::-webkit-scrollbar-track {
      display: none !important;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ccc !important;
    }
    &::-webkit-scrollbar {
      width: 6px !important;
    }
  }
  .ant-pagination {
    &-item {
      &-active {
        background-color: #fff !important;
        > a {
          color: #5156be !important;
        }
      }
    }
  }
}

.ant-pro-layout-watermark-wrapper {
  height: 100%;
}

.CustomCascader {
  & .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    font-weight: inherit;
  }

  & .ant-select-arrow {
    .ic {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  & .ant-select-dropdown {
    .ant-cascader-menu {
      padding: 4px;

      &:last-of-type {
        border-right: none;
      }

      &-item {
        margin-bottom: 2px;
        border-radius: 3px;

        &-active {
          background: rgba($primary-color, 0.08);
          color: $primary-color;
        }
      }

      &::-webkit-scrollbar-track {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
      }

      &::-webkit-scrollbar {
        width: 6px !important;
      }
    }
  }
}

.hover-scrollbar-show {
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      display: block;
    }
  }
}

.hover-active-icon-color {
  position: relative;
  &::before {
    position: relative;
    z-index: 1;
  }
  border-radius: 4px;
  &:hover {
    background-color: #eee !important;
  }
  &:active {
    color: $primary-color !important;
    background-color: $primary-color-8 !important;
  }
}

.hover-border-color {
  &:hover {
    border-color: $primary-color !important;
  }
}

.hover-bg-color {
  &:hover {
    background-color: $primary-color-8 !important;
  }
}
.active-bg-color {
  &:active {
    background-color: $primary-color-8 !important;
  }
}

.hover-text-color {
  &:hover {
    color: $primary-color !important;
  }
}
.active-text-color {
  &:active {
    color: $primary-color !important;
  }
}

.table_operate_zLtremm {
  display: flex;
  gap: 12px;
  .text-primary-btn {
    user-select: none;
    &.disabled {
      cursor: not-allowed;
      color: $primary-color-disabled;
    }
  }
}
