// 最新设计稿地址：
// https://mastergo.com/file/114003907134232?page_id=1%3A030&shareId=114003907134232

@charset "UTF-8";

// switch
//-----------------------------------------------------
$borderBoxSwitch: true !default; // true为*{box-sizing:border-box;}

// font相关
//-----------------------------------------------------
$font-size: 14px !default;
$pad-font-size: 12px !default;
$font-line-height: 1.5 !default;
$font-family: "San Francisco UI", "Helvetica Neue", Arial, "DIN Alternate", "PingFang SC", "Hiragino Sans GB",
  "Micrisoft YaHei" !default;

// 主色调
$primary-color: #444dff !default; // 主色
$primary-color-light: #f0f3ff; // 亮
$primary-color-hover: #6e7aff !default; // 悬浮
$primary-color-active: #3033d9 !default; // 点击
$primary-color-disabled: #bfc9ff !default; //禁用
$primary-color-focus: #d9e1ff !default;
$primary-color-border: #e8ecff;
$primary-color-line: #d9d9d9; //分割线
$primary-color-table-border: #dcdcdc; //表格边框
$primary-color-table-bg: #f5f7fa; //表头背景
$primary-color-table-close: #f9f9f9; //表格禁用项
// 主色 4%
$primary-color-4: #f8f8fd;
// 主色 8%
$primary-color-8: #f1f2fa;
// 主色 12%
$primary-color-12: rgba(81, 86, 190, 0.12);

$colorPrimary: $primary-color; // for wtdc components
$colorPrimaryLight: $primary-color-light; // for wtdc components 指向色

// 错误
$error-color: #fa5652;
$error-color-light: #fff2f2;
$error-color-hover: #e64f4c;
$error-color-active: #ad352f;
$error-color-disabled: #ffb9b0;

// 警告
$warning-color: #ffa100;
$warning-color-light: #fff8eb;
$warning-color-hover: #fa9550;
$warning-color-active: #be5a00;
$warning-color-disabled: #ffb98c;

// 成功
$success-color: #2ab57d;
$success-color-light: #eef9f5;
$success-color-hover: #56c08d;
$success-color-active: #008858;
$success-color-disabled: #92dab2;

// font color
$font_gy_1: rgba(0, 0, 0, 0.9); // Primary
$font_gy_2: rgba(0, 0, 0, 0.6); // Secondary
$font_gy_3: rgba(0, 0, 0, 0.4); // Placeholder
$font_gy_4: rgba(0, 0, 0, 0.26); // Disabled
$font_gy_5: rgba(0, 0, 0, 0.1);
$font_wh_1: rgba(255, 255, 255, 0.9); // Anti
$font_wh_2: rgba(255, 255, 255, 0.55);
$font_wh_3: rgba(255, 255, 255, 0.35);
$font_wh_4: rgba(255, 255, 255, 0.22);
// 中性色 neutral color
$neutral_color_1: #dcdcdc; // 中性色1：#DCDCDC（滚动条、输入框边框色、不可用色）
$neutral_color_2: #eeeeee; // 中性色2：#EEEEEE（页面容器、表格边框）
$neutral_color_3: #f3f3f3; // 中性色3：#F3F3F3（下拉筛选等Hover状态）
$neutral_color_4: #f9f9f9; // 中性色4：#F9F9F9（大面积使用的背景色）
$neutral_color_5: #f5f7fa; // 中性色5：#F5F7FA（表格表头）

//文本色
$text-color: $font_gy_1; //主色
$text-color-anti: $font_wh_1; //主色反色
$text-color-secondary: $font_gy_2; // 辅色
$text-color-secondary-anti: $font_wh_2; // 辅色反色
$text-color-placeholder: $font_gy_3; //占位符
$text-color-placeholder-anti: $font_wh_3; // 占位符反色
$text-color-disabled: $font_gy_4; //禁用
$text-color-disabled-anti: $font_wh_4; // 禁用反色
$text-color-link: $primary-color; //链接色
$test-step-color: #c1c4d4;

//容器色
$bg-color: #f8f9fd !default; //背景遮罩色
$bg-color-container: #ffffff; //主要容器
$bg-color-container-hover: #f3f3f3;
$bg-color-container-active: #e7e7e7;

$bg-color-secondarycontainer: #f3f3f3; //次要容器
$bg-color-secondarycontainer-hover: #eeeeee; //次要容器
$bg-color-secondarycontainer-active: #dcdcdc; //次要容器

$bg-color-component: #d9e1ff; //组件背景色
$bg-color-component-hover: #dcdcdc; //组件背景色
$bg-color-component-active: #a6a6a6; //组件背景色
$bg-color-component-disabled: #eeeeee; //组件背景色

//分割线
$stroke-color: #e7e7e7;
//边框色
$border-color: $neutral_color_1;
$border-color-secondary: #aaaaaa;

$white: white !default;
$black: black !default;

// 投影类型
$hover-shadow: 0 1px 10px rgba(0, 0, 0, 5%), 0 4px 5px rgba(0, 0, 0, 8%), 0 2px 4px -1px rgba(0, 0, 0, 12%); /** 鼠标悬浮阴影 */
$select-shadow: 0 3px 14px 2px rgba(0, 0, 0, 5%), 0 8px 10px 1px rgba(0, 0, 0, 6%), 0 5px 5px -3px rgba(0, 0, 0, 10%); /** 下拉框悬浮阴影 */
$modal-shadow: 0 6px 30px 5px rgba(0, 0, 0, 5%), 0 16px 24px 2px rgba(0, 0, 0, 4%), 0 8px 10px -5px rgba(0, 0, 0, 8%); /** 弹出层阴影 */
$shadow-1: $hover-shadow;
$shadow-2: $select-shadow;
$shadow-3: $modal-shadow;

// base values
$margin-base: 20px !default;
$margin: 16px !default;
$padding-base: 20px !default;
$padding: 16px !default;
$margin-gap: 8px !default;
$padding-content: 24px !default;

// 圆角类型
$radius-small: 4px !default; //小圆角
$radius-base: 6px;
$radius-large: 12px !default; //大圆角

// bg colo
// 弹窗头部背景颜色
$popup-bg: #f4f6f9;
// form
$inputWidth: 256px !default;

// media
$tablet-width: 1024px;
$laptop-width: 1280px;
$desktop-width: 1920px;

// ------------ START: 后面尽量不要用这些常量 ------------
$gray1: $neutral_color_3;
$gray2: $neutral_color_2;
$gray3: #e7e7e7; //布局边框使用此值
$gray4: #dcdcdc; //列表边框使用此值
$gray5: #c5c5c5;
$gray6: #a6a6a6;
$gray9: #5e5e5e;
$gray17: #f7f7f7;
$gray19: #767676;
$gray22: $neutral_color_4;
$error_1: $error-color-light; // Light
$error_5: $error-color-hover; // Hover
$error_6: $error-color; // Normal
$success_4: $success-color-hover; // Hover
$success_5: $success-color; // Normal
// Brand 品牌/Brand-color-list bg-close
$bg_close: $neutral_color_4;
// 兼容“知识商城”样式
$gray_3: #333;
// ------------ END ------------

$fw500: var(--fw500);
$fw600: var(--fw600);
